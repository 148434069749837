/* AccordionMenu.module.css */
.menuContainer {
  position: relative;
  
}

.menuButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 10px 0; /* Keep vertical padding */
  background-color: #FFF;
  color: #D5AF34;
  cursor: pointer;
  transition: background-color 0.2s ease; /* Add transition for color change */
  font-size: 1.2em;
  border: 0px;
  
}

.icon {
  width: 30px; /* Adjust the size of the circle */
  height: 30px; /* Adjust the size of the circle */
  margin-left: 5px;
  fill:#D5AF34;
}

.menuButton:hover {
  background-color: #FFF;
  color: #D5AF34;
}

.container {
  position: relative;
}

.menu {
  position: absolute;
  top: 100%;
  left: 0;
  border: 0px;
  list-style: none;
  margin: 0;
  padding: 0;
  display: none;
  text-align: center;
  font-size: 1.2em;
  text-decoration: none;
  letter-spacing: 1px;
}

.open {
  display: block;
  position: relative;
}

.menu li a {
  display: flex;
  flex: 1;
  color: #284F3F;
  /* text-align: center; */
  padding: 14px 16px;
  text-decoration: none;
  justify-content: center;
}


.menu li a:hover {
  background-color: #FFF;
  color: #D5AF34;
}

.activeNav {
  background-color: #ddd;
}
