.container {
  display: grid;
  grid-template-columns: 1fr 2fr;
  max-width: 960px;
  margin: 60px auto;
}

.content {
  padding-right: 30px;
  margin-left: 5px;
}

.sidebar {
  padding-left: 30px;
  margin-right: 30px;
  margin-top: 150px;
}

.sidebar form {
  padding: 20px;
  background: #D5AF34;
  border-radius: 10px;
}

.sidebar input,
.sidebar select {
  display: block;
  width: 100%;
  padding: 10px;
  margin-top: 8px;
  box-sizing: border-box;
  border: 0;
  border-radius: 4px;
  color: #555;
  font-size: 1em;
}

.sidebar label {
  margin: 0 auto 20px;
  display: block;
  color: #fff;
}

.sidebar button {
  color: #fff;
  border: 2px solid #fff;
  padding: 6px 12px;
  background-color: transparent;
  font-size: 1em;
  border-radius: 4px;
  cursor: pointer;
  display: block;
  width: 90%;
  min-width: 150px;
}

.sidebar h3 {
  color: #284F3F;
  margin-bottom: 20px;
}

.sidebar aside {
  margin-top: 40px;
  color: #555;
}

.sidebar aside li {
  margin: 10px;
}

/* transactions */
.transactions li {
  margin: 30px;
  border: 1px solid #f2f2f2;
  box-shadow: 3px 3px 5px rgba(50, 50, 50, 0.1);
  padding: 20px;
  display: flex;
  /* Use flex to create a grid layout */
  align-items: center;
  position: relative;
  overflow: hidden;
  border-left: 4px solid #D5AF34;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  /* Adjust the number of columns as needed */
  gap: 10px;
  overflow: visible;
  /* Adjust the gap between grid items as needed */
}

/* Adjust grid layout properties as needed */
.transactions .grid {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  /* Adjust the number of columns as needed */
  gap: 10px;
  /* Adjust the gap between grid items as needed */
}

.transactions .name {
  grid-column: 1/2;
  grid-row: 1;
  color: #777;
  font-size: 1.4em;
  /* You can apply grid properties to individual elements within the grid if needed */
}

.transactions .lastname {
  font-weight: bold;
  font-size: 1.6em;
  grid-row: 1;
  grid-column: 2/2;
  /* You can apply grid properties to individual elements within the grid if needed */
}

.transactions .rsvp {
  grid-column: 3/6;
  grid-row: 1;
  color: #777;
  font-size: 1.4em;
}


.transactions button.edit {
  position: absolute;
  /* display: inline-block; */
  top: 0;
  right: 0;
  background: #ffffff00;
  color: #777;
  border: none;
  padding: 5px 8px;
  text-align: center;
  line-height: 0;
  cursor: pointer;
}

.transactions .details {
  font-size: 0.6em;
}

.options {
  grid-row: 1;
  grid-column: 2/6;
  border: 0px solid #ccc;
  padding: 16px;
  border-radius: 4px;
  margin-top: 8px;
  margin-right: 30px;

}

.options .label {
  padding: 16px 20px;
}

.transactions select {
  border-width: 2px;
  border-radius: 4px;
  color: #777;
  text-align: center;
  margin-left: auto;
  margin-right: 40px;
  width: 100%;
  padding: 16px 20px;
}

.transactions select option {
  background-color: #fff;
  /* Set the background color of each option */
  color: #333;
  /* Set the text color of each option */
  padding: 5px 10px;
  /* Add padding to each option for spacing */
  font-size: 14px;
  /* Set the font size of each option */
}



.save {
  color: #fff;
  border: 2px solid #fff;
  padding: 12px 12px;
  background-color: #D5AF34;
  font-size: 1em;
  border-radius: 10px;
  cursor: pointer;
  display: block;
  width: 80%;
  min-width: 200px;
  margin-left: auto;  /* Add this line */
  margin-right: auto; /* Add this line */
}


.error {
  color: #ff0000;
  grid-column: 1/6;
}

.titleContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.content {
  color: #000;
}

.set {
  color: #fff;
  border: 2px solid #fff;
  padding: 6px 12px;
  background-color: #D5AF34;
  font-size: 1em;
  border-radius: 10px;
  cursor: pointer;
  display: block;
  width: 20%;
  min-width: 200px;
}


.selected {
  background-color: #ddd;
}

@media screen and (max-width:770px) {
  .container {
    grid-template-columns: 1fr;
  }

  .sidebar {
    margin-top: -30px;
  }

  .transactions {
    align-items: center;
  }

  .transactions .name {
    grid-column: 1/6;
    grid-row: 1;
    color: #777;
    font-size: 1.4em;
    /* You can apply grid properties to individual elements within the grid if needed */
  }

  .transactions .rsvp {
    grid-row: 2;
    grid-column: 1/6;
  }

  .transactions .lastname {
    font-weight: bold;
    font-size: 1.6em;
    grid-row: 1;
    grid-column: 1/6;
    /* You can apply grid properties to individual elements within the grid if needed */
  }

  .transactions .options {
    grid-column: 1/6;
    grid-row: 2;
    align-items: center;
  }
}

.hiddenRadio {
  display: none;
}

.optionsRow {
  display: flex;
  justify-content: space-between; /* Distributes space between items */
}

.selectableBox {
  display: inline-block;
  border: 0px;
  padding: 10px 20px;
  border-radius: 4px;
  margin-right: 0px;
  flex-grow: 1; /* Allows the box to grow and fill the available space */
  text-align: center;
  cursor: pointer;
  background-color: #d5af342b;
  transition: background-color 0.3s, border-color 0.3s;
}

/* Change background and border color when radio button is checked */
.hiddenRadio:checked+label {
  background-color: #D5AF34;
  color: white;
}

.selectableBox:not(:last-child) {
  margin-right: 10px;
}

.selectReact {
  background-color: #D5AF34;
}

.checkBox {
  top: 0;
  left: 0;
  height: 15px;
  width: 15px;
  background-color: #eee;
  color: #D5AF34;
}

.textArea {
  width: 100%;
  height: 85px;
  padding: 12px 20px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #FFF;
  font-size: 16px;
  resize: none;
}



/* .transactions button.delete {
  position: absolute;
  top: 0;
  right: 300px;
  background: #ddd;
  color: #777;
  border: none;
  padding: 12px 8px;
  text-align: center;
  line-height: 0;
  font-size: 0.9em;
  cursor: pointer;
} */