.footer {
    background-color: #D5AF34; /* Example block color */
    color: #284F3F;
    text-align: center;
    padding: 20px 0;
    position: relative;
    bottom: 0;
    
    border-top: 2px solid #D5AF34;
    width: 100% /* Set the width of the footer */
}

.footer p {
    color: #284F3F;
    font-size: 12px;
}

.footer a {
    color: #284F3F;
    margin: 0 10px;
    text-decoration: none;
    font-size: 16px;
}

li a:hover {
    background-color: #D5AF34;
    color: #FFF;
  }
