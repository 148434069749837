/* ItineraryItem.module.css */
.container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  color: #666;
}

.itineraryItem {
  display: flex;
  align-items: center;
  position: relative;
  margin-left: 50px; /* Adjust as needed */
  padding: 20px;
}

.iconContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white; /* or any color you prefer*/
  border-radius: 50%; /* makes the div circular */
  padding: 10px; /* adjusts the size of the gap */
  border: 3px solid #D5AF34; /* width and color of the circular border */
  width: 31px; /* width of the container */
  height: 31px; /* height of the container */
  position: absolute;
  left: -50px;
  z-index: 2;
}

.timelineCircle {
  width: 40px; /* Adjust the size of the circle */
  height: 40px; /* Adjust the size of the circle */
  border-radius: 50%; /* Make it circular */
  
  border: 2px solid #D5AF34;
  background-color: #D5AF34; /* Circle color */
  position: absolute;
  /* left: -50px; Adjust according to your layout */
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon {
  width: 70%; /* Adjust the size of your icon inside the circle */
  height: auto;
  filter: brightness(0) invert(1);
}

.timeEvent {
  
}

.time {
  font-weight: bold;
  font-size: 1.2em;
}

.location {
  font-style: italic;
  font-size: 0.9em;
}

.extra {
  font-size: 0.7em;
}

/* Add your existing styles for .time and .event */
