.countdownContainer {
    background: #ffffff00;
    
    border-radius: 10px;
    box-shadow: none;
    text-align: center;
}
.countdownTitle {
    color: #fff;
    font-size: 1.2em;
    margin-bottom: 0px;
    
}
.countdown {
    display: flex;
    justify-content: center;
}
.timeSegment {
    background: #d5af3400;
    color: #fff;
    padding: 0px 10px;
    border-radius: 5px;
    font-size: 1.5em;
    display: flex;
    flex-direction: column;
}
.timeSegment:not(:last-child) {
    margin-right: 5px;
}
.timeLabel {
    color: #777;
    font-size: 0.5em;
    text-transform: uppercase;
    letter-spacing: 1px;
}

@media screen and (max-width:580px) {
    .countdownTitle {
        min-width: none;
        font-size: 0.6em;
    }

    .timeSegment {
        font-size: 0.8em;
    }
}