 
  h1 {
    text-align: center;
    color: #333;
    margin-bottom: 20px;
  }


.faqContainer {
    width: 85%;
    max-width: 900px;
    margin: 0 auto;
    padding: 20px;
    overflow-x: hidden;
}

.faqItem {
    /* background: #f9f9f9; */
    margin-bottom: 10px;
    border-radius: 4px;
    /* border-width: 2px; */
    border: 1px solid #f2f2f2;
    box-shadow: 3px 3px 5px rgba(50, 50, 50, 0.1);
    border-left: 4px solid #D5AF34;
    /* box-shadow: 0 2px 5px rgba(0,0,0,0.2); */
}

.faqQuestion {
    padding: 15px;
    cursor: pointer;
    user-select: none;
    font-weight: bold;
    color: #777;
    font-size: 1.4em;
}

.faqAnswer {
    padding: 15px;
    display: none;
    border-top: 1px solid #ddd;
}

.faqAnswer.active {
    display: block;
}

.smallText {
    font-size: 0.1em; /* Adjust the size as needed */
    color: aliceblue;
}
