/* Faq.module.css */

/* General Page Styling */
.container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    color: #666;
}

h1 {
    text-align: center;
    color: #333;
    margin-bottom: 20px;
}

p {
    color: #666;
    line-height: 1.6;
    margin-bottom: 10px;
}

.dateHeading {
    font-weight: bold;
    /* Makes the text bold like a heading */
    margin-right: 5px;
    /* Adds a small space after the heading */
    /* Add other styling as needed to make it resemble a heading */
}

/* ... existing styles ... */

.topContent {
    display: flex;
    align-items: center;
    /* Aligns items vertically in the center */
    gap: 20px;
    /* Space between the text and the image */
}

.topText {
    flex: 1;
    /* Allows the text to fill the available space */
}

.topImage img {
    flex-shrink: 0;
    /* Prevents the image from shrinking */
    max-width: 200px;
    /* Adjust as needed */
    height: auto;
    height: auto;
    display: block;
    margin: 20px auto;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.imageDescription {
    text-align: center; /* Center the text */
    padding: 10px; /* Add some padding */
    font-style: italic; /* Italicize the text */
    color: #666; /* Set the text color */
    font-size: 0.9em; /* Adjust font size */
    margin-top: 5px; /* Space between image and description */
    max-width: 200px;
  }

/* ... other styles ... */


/* Custom Bullet Point Style */

.bulletPointList {
    list-style: none;
    /* Removes default list styling */
    padding-left: 0;
    /* Removes default padding */
}

.bulletPointList li {
    margin-bottom: 10px;
    /* Spacing between items */
    position: relative;
    /* Needed for custom bullet positioning */
    padding-left: 25px;
    /* Space for the custom bullet */
}

.bulletPointList li::before {
    content: '-';
    /* The custom bullet character */
    color: #D5AF34;
    /* Bullet color */
    font-size: 20px;
    /* Bullet size */
    position: absolute;
    left: 0;
    top: 0;
}

.mainImg {
    /* position: relative; */
    display: inline-block;
    margin-bottom: 5%;

}

/* Image Styling */
.mainImg img {
    max-width: 50%;
    height: auto;
    display: block;
    margin: 20px auto;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* FAQ Item Styling */
.faqContainer {
    width: 85%;
    max-width: 900px;
    margin: 0 auto;
    padding: 20px;
    overflow-x: hidden;
}

.faqItem {
    /* background: #f9f9f9; */
    margin-bottom: 10px;
    border-radius: 4px;
    /* border-width: 2px; */
    border: 1px solid #f2f2f2;
    box-shadow: 3px 3px 5px rgba(50, 50, 50, 0.1);
    border-left: 4px solid #D5AF34;
    /* box-shadow: 0 2px 5px rgba(0,0,0,0.2); */
}

.faqQuestion {
    padding: 15px;
    cursor: pointer;
    user-select: none;
    font-weight: bold;
    color: #777;
    font-size: 1.4em;
}

.faqAnswer {
    padding: 15px;
    display: none;
    border-top: 1px solid #ddd;
}

.faqAnswer.active {
    display: block;
}

.smallText {
    font-size: 0.1em;
    /* Adjust the size as needed */
    color: aliceblue;
}

@media screen and (max-width:580px) {
    .topContent {
      display: inline-block;
    }
    .topImage img {
        max-width: 300px;
       
    }
  }