.navbar {
  width: 100%;
  align-items: center;
  text-align: center;
  margin: auto;
}

.numbertwo {
  border-bottom: 2px solid #D5AF34;
  /* background-color: #f0f0f0; */
}

.navbar ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  max-width: 920px;
  display: flex;
  justify-content: center;
  /* Center horizontally */
  align-items: center;
  /* Center vertically */
  margin: 0 auto;
  
}
.activeNav {
  background-color: #ddd;
}

.navbar li {
  float: left;
}

.navbar li a {
  display: flex;
  flex: 1;
  color: #284F3F;
  /* text-align: center; */
  padding: 14px 16px;
  text-decoration: none;
  min-height: 52px;
  min-width: 70px;
  justify-content: center;
}

.numbertwo li a:hover {
  background-color: #D5AF34;
  color: #FFF;
}

.title {
  margin-right: auto;
  font-weight: bold;
  letter-spacing: 1px;
  font-size: 1.2em;
}

.subtitle {
  margin-left: auto;
  letter-spacing: 1px;
  padding: 14px 16px;
  font-size: 1.2em;
}

.img {
  width: 149px;
}

.img a:hover svg{
  fill:#FFF
}

.hidden {
  display: none;

  @media screen and (min-width: 920px) {
    display: block;
  }
}


/* @media screen and (min-width: 920px) {
  .navbar ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    max-width: 920px;
    display: flex;
    justify-content: center;
    align-items: center; 
    margin: 0 auto;
  }
} */