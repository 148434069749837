/* Meals.module.css */

.itemsContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
}

.item {
  margin: 10px;
  text-align: center;
  border-bottom: 1px solid #ccc; /* Add a horizontal bar */
  padding-bottom: 10px; /* Add some padding below each item */
}

.centeredImage {
  max-width: 100%;
  height: auto;
}
