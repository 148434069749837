.seatMap {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    margin: 20px;
  }
  
  .deck {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .row {
    display: flex;
    justify-content: center;
    gap: 10px;
  }
  
  .seat {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #ccc;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .seat:hover {
    background-color: #e6e6e6;
  }
  
  .selected {
    background-color: #D5AF34;
    color: #fff;
    border-color: #D5AF34;
  }
  .selectedByOther {
    background-color: #d4c48f;
    color: #fff;
    border-color: #D5AF34;
  }
  .blankSpace {
    width: 32px;
    height: 0;
    margin: 0;
    padding: 0;
  }
  