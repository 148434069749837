.container {

  /* grid-template-columns: 4, 1fr; */
  max-width: 1440px;
  overflow-x: hidden;
  margin: 60px auto;
  align-items: center;
  position: relative;
  display: block;

}
.destinations {
  padding: 10px;
  max-width: 960px;
  align-items: center;
  margin: auto 20%;
}
.img2 {
  width: 960px;
} 
.mainimage {
    /* display: block; */
    /* grid-column: 1/4; */
    max-width: 1440px;
    overflow: hidden;
    width: 98%;
    height: auto;
    padding: 10px;
    border-radius: 30px;
  }
.imageContainer {
  /* position: relative; */
  display: inline-block;
  margin-bottom: 5%;
  
}
/* Style the image */
.imageContainer img {
  display: inline-block;
  width: 100%;
  height: auto;
  max-width: 1440px;
  overflow: hidden;
  width: 98%;
  height: auto;
  padding: 10px;
  border-radius: 30px;
}

/* Style the overlay-text */
.overlayText {
  position: absolute;
  width: 30%;

  top: 30%; /* Adjust this value to vertically center the text */
  left: 80%; /* Adjust this value to horizontally center the text */
  transform: translate(-50%, -50%); /* Center the text precisely */
  background-color: #D5AF34; /* Set the background color with some transparency */
  color: #fff; /* Set the text color */
  padding: 10px 20px; /* Add some padding to the text */
  font-size: 20px; /* Adjust the font size */
  border-radius: 10px;
/*   text-align: center; /* Center the text horizontally */ 
}


.box2 {
  display: inline-block;
  background-color: #D5AF34;
  color: #fff;
  border-radius: 10px;
  padding: 10px 20px;
  margin: 20px;
  align-items: center;
  width: 80%;
}
.box3 {
  display: inline-block;
  border: 1px;
  border-color: #D5AF34;
  border-top-style: solid;
  color: #284F3F;
  padding: 10px 20px;
  align-items: center;
  width: 95%;
  
}
  .box3:hover {
    background-color: #f5f5f5;
    font-size: 0.9em;
  }
.box4 {
  padding: 10px;
}

@media screen and (max-width:960px) {
  .overlayText {
    top: 18%; /* Adjust this value so the box doesn't cover the text */
    left: 50%;
    transform: translate(-50%, -50%); /* Center the box */
    width: 90%;
    padding: 10px; /* Add some space inside the box */
    box-sizing: border-box; /* Include padding in the box's width */
  }
}

@media screen and (max-width:770px) {
  .imageContainer img {
    transform: scale(1.5);
  }
  .imageContainer {
    margin-bottom: 20%;
  }
  .overlayText {
    /* top: 18%; Adjust this value so the box doesn't cover the text */
    z-index: 1; /* Ensure it's above the image but below the text */
  }
}

@media screen and (max-width:580px) {
  .imageContainer {
    margin-bottom: 20%;
  }
  .overlayText {
    top: 10%; /* Adjust this value so the box doesn't cover the text */
  }
}
