.container {
    position: relative;
    max-width: 400px;
    margin: 0 auto;
    padding-top: 30px; /* Half the height of the icon container to start the line from the first icon */
    padding-bottom: 30px; /* Half the height of the icon container to end the line at the last icon */
    color: #666;
}

.timelineLine {
    position: absolute;
    left: 7%; /* Adjust this to align with the center of your icons */
    top: 150px; /* Start the line at the vertical center of the first icon */
    bottom: 80px; /* End the line at the vertical center of the last icon */  
    height: auto; /* This will expand to the height of the container */
    width: 3px; /* Adjust the width for the thickness of your line */
    background-color: #D5AF34; /* Adjust the color to match your design */
    z-index: 1; /* Make sure this is below your icons */
  }

.flightPlan {
    border-left: 2px solid #000; /* Line on the left */
    position: relative;
    padding-left: 20px; /* Adjust as needed */
    /* Your other styles for the flight plan container */
  }