.container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    color: #666;
}
h1 {
    text-align: center;
    color: #333;
    margin-bottom: 20px;
}
h2 {
    padding-top: 20px;
}
p {
    color: #666;
    line-height: 1.6;
    margin-top: 20px;
    margin-bottom: 20px;
}
.icon {
    width: 70%;
    height: auto;
    filter: brightness(0) invert(1);
  }
.bulletPointList {
    list-style: none;
    padding-left: 0;
}
.bulletPointList li {
    background-image: url('../../assets/plane-icon.svg');
    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: 20px 20px; 
    padding-left: 30px; 
    margin-bottom: 10px;
}
li a:hover {
    background-color: #fff;
    color: #666
  }
.topContent {
    display: flex;
    align-items: center;
    gap: 20px;
}
.topText {
    flex: 1;
}
.smallText {
    font-size: 0.1em;
    /* Adjust the size as needed */
    color: aliceblue;
}
.emailLink {
    word-break: break-word;
    /* This ensures that the email address will wrap */
}
.mainImg {
    /* position: relative; */
    display: inline-block;
    margin-bottom: 5%;
    position: relative;
    
}
.mainImg img {
    max-width: 90%;
    height: auto;
    display: block;
    margin: 20px auto;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.topInfo {
    text-align: center;
    /* font-family: Regular; */
    font-size: 1.5em;
}
.countdownOverlay {
    position: absolute;
    top: 25%; /* Adjust this value to position vertically as you like */
    left: 50%;
    transform: translate(-50%, -50%);
    background:#00000050; /* Semi-transparent background */
    padding: 10px;
    border-radius: 10px;
    max-width: 90%;
  }
.dateHeading {
    font-weight: bold;
    margin-right: 5px;
}
@media screen and (max-width:770px) {


    .countdownOverlay {
        /* top: 65%; */
        /* background:#000000bb; */
        z-index: 1;
    }

    .topImage img {
        max-width: 300px;

    }
}
@media screen and (max-width:560px) {
    .topInfo {
        font-size: 1.2em;
    }
    
}