.container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    color: #666;
}

.dateHeading {
    font-weight: bold;
    /* Makes the text bold like a heading */
    margin-right: 5px;
    /* Adds a small space after the heading */
    /* Add other styling as needed to make it resemble a heading */
}

.mainImg img {
    position: relative;
    max-width: 90%;
    height: auto;
    display: block;
    margin: 20px auto;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}