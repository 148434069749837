/* Transactions list item styles */
.info{
  text-align: center;
}
.transactions li {
  margin: 20px auto;
  border: 1px solid #f2f2f2;
  box-shadow: 3px 3px 5px rgba(50,50,50,0.1);
  padding: 20px;
  display: flex;
  flex-direction: column; /* Changed to column to stack namesRow and optionsRow */
  align-items: flex-start; /* Align items to the start */
  border-left: 4px solid #D5AF34;
  max-width: 600px;
}

.namesRow {
  display: flex; /* Use flexbox for layout */
  justify-content: flex-start; /* Align items to the start of the container */
  align-items: center; /* Center items vertically */
  padding: 10px 0; /* Optional padding for better spacing */
}

.transactions .name, .transactions .lastname {
  color: #777;
}

.transactions .lastname {
  font-weight: bold;
}

/* Options row alignment */
.transactions .optionsRow {
  text-align: right;
  width: 100%; /* Ensure it occupies the full width */
}

.transactions .optionsRow p {
  text-align: left;
}

/* Selectable box styles */
.selectableBox {
  display: inline-block; /* Changed to inline-block for alignment */
  border: 0px;
  padding: 10px 10px;
  border-radius: 0px;
  min-width: 100px;
  text-align: center;
  cursor: pointer;
  background-color: #d5af342b;
  transition: background-color 0.3s, border-color 0.3s;
}

/* Hidden radio button styles */
.hiddenRadio {
  display: none;
}

.hiddenRadio:checked + label {
  background-color: #D5AF34;
  color: white;
}

/* Selected list item style */
.selected {
  background-color: #efefef;
}

/* Media query for screen sizes below 570px */
@media screen and (max-width: 620px) {
  .transactions .optionsRow {
    text-align: center; /* Align items to the left for smaller screens */
    display: flex; /* Use flexbox */
    flex-direction: column; /* Stack children vertically */
  }

  .selectableBox {
    
    width: 90%; /* Full width for smaller screens */
    margin-bottom: 10px; /* Add some space between options */
  }
}