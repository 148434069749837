/* src/components/CaperunResults.module.css */
.container {
    margin: 20px;
  }
  
  .table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .table th, .table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }
  
  .table th {
    background-color: #f2f2f2;
  }
  
  .userRow:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  .userRow:hover {
    background-color: #f1f1f1;
  }
  
  .searchBox {
    width: 100%;
    max-width: 400px;
    padding: 10px;
    margin-bottom: 20px;
    font-size: 16px;
    font-family: inherit; /* Ensures the font matches the rest of the website */
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box; /* Ensures padding doesn't affect the width */
  }
  