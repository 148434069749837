.tabs {
    display: flex;
    justify-content: space-between;
    background-color: #f0f0f0;
  }
  
  .tab {
    flex: 1;
    padding: 10px;
    text-align: center;
    cursor: pointer;
    border-bottom: 1px solid #D5AF34;
  }
  
  .tab:hover {
    background-color: #D5AF34;
    color: #FFF;
  }
  
  .activeTab {
    background-color: #ddd;
  }
  